import { theme, Typography } from "antd";
import { LayoutMinWidth, LayoutSpace } from "utils/const";
import { FileUpload } from "./upload";

import "./index.css";

const { Title, Paragraph, Text } = Typography;

export const DataUpload = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div style={{ overflow: "auto", height: "100%", borderRadius: "6px" }}>
      <div
        style={{
          padding: LayoutSpace,
          height: "100%",
          backgroundColor: colorBgContainer,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          minWidth: LayoutMinWidth,
        }}
      >
        <div style={{ width: "36rem", height: "12rem" }}>
          <Typography>
            <Title level={5} style={{ textAlign: "center" }}>
              文件上传说明
            </Title>
            <Paragraph>
              <Text>
                1、系统仅支持2G以内的STL模型文件，请将上传的文件，压缩成ZIP或者RAR文件后上传
              </Text>
            </Paragraph>
            <Paragraph>
              <Text>
                2、STL文件应符合以下要求：
                <ul className="upload-ul">
                  <li>压缩包名字应以PatientID进行命名，例：100034251</li>
                  <li>不包含非STL格式的文件</li>
                </ul>
              </Text>
            </Paragraph>
          </Typography>
        </div>
        <FileUpload />
      </div>
    </div>
  );
};
