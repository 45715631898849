type IDType = number | string;

export const LOGIN = "/api/v1/login/login";
export const LOGIN_DINGTALK = "/api/v1/login/dingtalk"; // /v1/login/dingtalk?authCode={}
export const TOKEN_LOGIN = "/api/v1/login/token";

export const GET_LIST = "/api/v1/study/list/";
export const GET_STUDY = "/api/v1/study/"; // id={}
export const GET_UPDATE_STUDY = (id: IDType, body_part: string) =>
  `/api/v1/study/update?id=${id}&body_part=${body_part}`;
export const DEL_STUDY = (id: IDType) => `/api/v1/study/?id=${id}`; // id={}
export const UPDATE_STUDY = (id: IDType) => `/api/v1/study/?id=${id}`;
export const EXPORT_LIST = "/api/v1/study/export/";

export const UPLOAD = "/api/v1/file/upload/";
export const UPLOAD_UPDATE = (folder_id: IDType, study_id: IDType) =>
  `/api/v1/file/confirm/?folder_id=${folder_id}&study_id=${study_id}`;

export const GET_BODY_PART = "/api/v1/configuration/body_part/";

export const GET_HIERARCHY = "/api/v1/configuration/hierarchy/";

export const ADD_CONFIG = "/api/v1/configuration/";
export const UPDATE_CONFIG = (id: IDType) => `/api/v1/configuration/?id=${id}`;
export const DELETE_CONFIG = (id: IDType) => `/api/v1/configuration/?id=${id}`;

export const ADD_RULE = "/api/v1/configuration/record";

export const GET_CONFIG_LIST = "/api/v1/configuration/list/";

export const GET_QR = "/api/v1/study/qrcode";
// export const VERIFY_CODE = (key: IDType, code: IDType) =>
//   `/api/v1/qrcode/verify/${key}/${code}/`;
