import { Dispatch, SetStateAction } from "react";
import { message, UploadFile } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload/interface";
import { toNumber } from "utils";
import * as auth from "auth-provider";

const token = auth.getToken();
export const UploadHeaders = {
  Authorization: token ? `jwt ${token}` : "",
};

// 2G
export const fileSize = 1024 * 1024 * 2048;

const isCanUpload = (file: RcFile | UploadFile<any>) => {
  const UploadFormatList = ["zip", "rar"];

  const fileNmae = file.name;
  const fileFormat = fileNmae
    .substring(fileNmae.lastIndexOf(".") + 1)
    .toLowerCase();
  return UploadFormatList.includes(fileFormat);
};

export const beforeUpload = (file: RcFile) => {
  const isLt2G = file.size <= fileSize;
  const isAllowedFileType = isCanUpload(file);

  if (!isAllowedFileType) {
    message.error("只支持上传ZIP或RAR包");
    return false;
  } else if (!isLt2G) {
    message.error(file.name + "文件不得大于2G，请调整后重试");
    return false;
  } else {
    return true;
  }
};

export const handleUpload = (
  info: UploadChangeParam<UploadFile>,
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>,
  setFolderId?: Dispatch<SetStateAction<string | number | undefined>>
) => {
  const { status, response } = info.file;
  if (status === "done" && setFolderId) {
    setFolderId(response.folder_id);
  }
  let newFileList: UploadFile[] = info.fileList.filter(
    (f) => toNumber(f.size) <= fileSize && isCanUpload(f)
  );
  newFileList.map((file) => {
    if (file?.response?.errorMsg) {
      file.response = file.response.errorMsg;
    }
    return file;
  });
  setFileList(newFileList);
};
