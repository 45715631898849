import { useRef } from "react";
// import { theme } from "antd";
import { ActionType } from "@ant-design/pro-components";

// import { LayoutSpace } from "utils/const";
import { DataListTable } from "./table";

export const DataList = () => {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  const actionRef = useRef<ActionType>();

  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
      }}
    >
      <DataListTable actionRef={actionRef} />
    </div>
  );
};
