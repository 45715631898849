import { Modal, Form, Input, Col, Select, ColorPicker, InputNumber, message } from "antd";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { ColorFactory } from "antd/es/color-picker/color";
import * as api from "../api";
import { trim } from "../util-view";

import "./add-config.css";

const TrimInput = trim(Input);

type FieldType = {
    level_id?: string;
    part_id?: number;
    display_name?: string;
    file_name?: number;
    default_opacity?: number;
    model_color?: string;
};

export default function View({ target, onOk, onCancel, isOpen, bodyParts }: any) {
    const [data, updateData] = useImmer({
        title: "",
        confirmLoading: false,
        bodyOptions: [] as any[],
        levelOptions: [] as any[],
    });
    const [formRef] = Form.useForm();

    const onSubmit = (values: any) => {
        updateData((draft) => {
            draft.confirmLoading = true;
        });
        values.id = target.id;
        values.priority = target.priority || 0;
        api.update_conf(values).then((resp) => {
            const fnMsg = resp.code === 1 ? message.info : message.warning;
            const msg = resp.msg || (resp.code === 1 ? "请求成功！" : "请求失败！");
            fnMsg(msg);
            if (resp.code === 1) {
                onOk(values);
            }
            updateData((draft) => {
                draft.confirmLoading = false;
            });
        });
    };

    const onChangePart = (value: any) => {
        let levelOptions = [] as any;
        for (let part of value ? data.bodyOptions : []) {
            if (part.value === value) {
                levelOptions = part.levels.map((it: any) => ({ value: it.id, label: it.name }));
                break;
            }
        }
        updateData((draft) => {
            draft.levelOptions = levelOptions;
        });
        formRef.setFieldValue("level_id", "");
    };

    useEffect(() => {
        let opacity = Number(target.default_opacity === null ? 50 : target.default_opacity);
        opacity = Object.is(opacity, NaN) ? 50 : opacity;
        const rgb = new ColorFactory(target.model_color || "#999999").toRgb();
        const initialValues = {
            default_opacity: opacity,
            model_color: new ColorFactory({ ...rgb, a: opacity / 100 }).toHexString(),
            display_name: target.display_name,
            file_name: target.file_name,
            part_id: target.part_id,
            level_id: target.level_id,
        };
        updateData((draft) => {
            draft.title = target.id ? "修改文件规则" : "新增文件规则";
            draft.confirmLoading = false;
            draft.bodyOptions = bodyParts.map((it: any) => ({ value: it.id, label: it.name, levels: it.levels }));
            for (let part of bodyParts) {
                if (part.id === target.part_id) {
                    draft.levelOptions = part.levels.map((it: any) => ({ value: it.id, label: it.name }));
                    break;
                }
            }
            formRef.setFieldsValue(initialValues);
        });
    }, [updateData, bodyParts, formRef, target]);

    return (
        <Modal width={800} centered={true} title={data.title} open={isOpen} confirmLoading={data.confirmLoading} onOk={() => formRef.submit()} onCancel={onCancel}>
            <Form labelCol={{span: 6}} form={formRef} onFinish={onSubmit}>
                <div className="row">
                    <Col span={11}>
                        <Form.Item<FieldType> label="部位" name="part_id" rules={[{ required: true, message: "请选择部位!" }]}>
                            <Select options={data.bodyOptions} allowClear={true} onChange={onChangePart} />
                        </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                        <Form.Item<FieldType> label="文件名称" name="file_name" rules={[{ required: true, message: "请输文件名称!" }]}>
                            <TrimInput />
                        </Form.Item>
                    </Col>
                </div>
                <div className="row">
                    <Col span={11}>
                        <Form.Item<FieldType> label="展示名称" name="display_name" rules={[{ required: true, message: "请输入显示名称!" }]}>
                            <TrimInput />
                        </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                        <Form.Item<FieldType> label="透明度" name="default_opacity">
                            <InputNumber
                                min={0}
                                max={100}
                                onChange={(alp) => {
                                    const rgb = new ColorFactory(formRef.getFieldValue("model_color")).toRgb();
                                    const color = new ColorFactory({ ...rgb, a: Number(alp) / 100 });
                                    formRef.setFieldValue("model_color", color.toHexString());
                                }}
                            />
                        </Form.Item>
                    </Col>
                </div>
                <div className="row">
                    <Col span={11}>
                        <Form.Item<FieldType> label="层级" name="level_id">
                            <Select options={data.levelOptions} allowClear={true} />
                        </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={11}>
                        <Form.Item<FieldType> label="颜色" name="model_color">
                            <ColorPicker
                                onChange={(color, hex) => {
                                    formRef.setFieldValue("default_opacity", Math.round(color.toRgb().a * 100));
                                    formRef.setFieldValue("model_color", hex);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </div>
            </Form>
        </Modal>
    );
}
