import { Modal, Spin, Form, Input, ColorPicker, Col, InputNumber, Select, message, DatePicker } from "antd";
import { useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { Table } from "antd";
import * as api from "../api";
import { trim, edit } from "../util-view";
import Row from "./alter-data-row";
import dayjs from "dayjs";
import { ColorFactory } from "antd/es/color-picker/color";

import "./alter-data.css";

const TrimInput = trim(Input);
const EditInput = edit(Input, true);
const EditInputNumber = edit(InputNumber, false);
const EditSelect = edit(Select);

const DATE = "YYYY-MM-DD";

export default function View({ target, onOk, onCancel, isOpen }: any) {
    const [data, updateData] = useImmer({
        commting: false,
        loading: false,
        rows: [] as any,
        editRow: {} as any,
        parts: [] as any,
        part: {} as any,
        bodyOptions: [] as any[],
        levelOptions: [] as any[],
    });
    const [studyForm] = Form.useForm();

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            updateData((draft) => {
                const activeIndex = draft.rows.findIndex((it: any) => it.id === active.id);
                const overIndex = draft.rows.findIndex((it: any) => it.id === over?.id);
                draft.rows = arrayMove(draft.rows, activeIndex, overIndex);
            });
        }
    };

    const onEditRow = (row: any, attr: any) => {
        updateData((draft) => {
            draft.rows = data.rows.map((it: any) => {
                if (it.id === row.id) {
                    const cfg = it.config || {};
                    return { ...it, config: { ...cfg, ...attr } };
                }
                return it;
            });
        });
    };

    const onSubmitStudy = (values: any) => {
        updateData((draft) => {
            draft.commting = true;
        });
        values.study_date = values.study_date ? dayjs(values.study_date).format(DATE) : null;
        values.body_part = data.part.name;
        values.age = values.age || null;
        values.attas = data.rows.map((it: any) => {
            return {
                id: it.id,
                config: it.config,
            };
        });
        api.update_study(target.id, values).then((resp) => {
            const fnMsg = resp.code === 1 ? message.info : message.warning;
            const msg = resp.msg || (resp.code === 1 ? "请求成功！" : "请求失败！");
            fnMsg(msg);
            updateData((draft) => {
                draft.commting = false;
            });
            if (resp.code === 1) {
                onOk();
                studyForm.resetFields();
            }
        });
    };

    const reload = useCallback(
        (part_id: string, part_name: string) => {
            updateData((draft) => {
                draft.editRow = {};
                draft.rows = [];
                draft.loading = true;
            });
            api.list_attachment(target.id, part_id, part_name).then((resp) => {
                updateData((draft) => {
                    draft.loading = false;
                    draft.rows = resp.attas || [];
                    draft.parts = resp.parts || [];
                    draft.bodyOptions = draft.parts.map((it: any) => ({ value: it.id, label: it.name }));
                    if (part_id) {
                        draft.part = draft.parts.filter((it: any) => it.id === part_id)[0] || {};
                    } else {
                        draft.part = draft.parts.filter((it: any) => it.name === target.body_part)[0] || {};
                    }
                    draft.levelOptions = (draft.part.levels || []).map((it: any) => ({ value: it.id, label: it.name }));
                    if (!part_id) {
                        const study_date = target?.study_date ? dayjs(target?.study_date) : null;
                        studyForm.setFieldsValue({ ...target, study_date, part_id: draft.part.id });
                    }
                });
            });
        },
        [updateData, target, studyForm],
    );

    useEffect(() => {
        if (isOpen) {
            reload("", target.body_part);
        }
    }, [isOpen, reload, target]);

    const columns = [
        {
            title: "排序",
            key: "sort",
        },
        {
            title: "文件名称",
            dataIndex: "file_name",
            render: (text: string, record: any) => {
                return text;
            },
        },
        {
            title: "展示名称",
            width: "20%",
            dataIndex: "display_name",
            render: (text: string, record: any) => {
                const cfg = record.config || {};
                return (
                    <EditInput
                        placeholder="填写名称"
                        defaultValue={cfg.display_name}
                        onChange={(value: any) => {
                            onEditRow(record, { display_name: value });
                        }}
                    />
                );
            },
        },
        {
            title: "颜色",
            dataIndex: "model_color",
            render: (text: string, record: any) => {
                const cfg = record.config || {};
                let opacity = Number(cfg.default_opacity === null ? 50 : cfg.default_opacity);
                opacity = Object.is(opacity, NaN) ? 50 : opacity;
                const rgb = new ColorFactory(cfg.model_color || "#999999").toRgb();
                const color = new ColorFactory({ ...rgb, a: opacity / 100 }).toHexString();
                return (
                    <ColorPicker
                        value={color}
                        onChange={(color: any, hex: any) => {
                            const opacity = Math.round(color.metaColor.a * 100);
                            onEditRow(record, { model_color: hex, default_opacity: opacity });
                        }}
                    />
                );
            },
        },
        {
            title: "透明度",
            dataIndex: "default_opacity",
            render: (text: string, record: any) => {
                const cfg = record.config || {};
                let opacity = Number(cfg.default_opacity === null ? 50 : cfg.default_opacity);
                opacity = Object.is(opacity, NaN) ? 50 : opacity;
                return (
                    <EditInputNumber
                        value={opacity}
                        min={0}
                        max={100}
                        onChange={(value: any) => {
                            const rgb = new ColorFactory(cfg.model_color || "#999999").toRgb();
                            const color = new ColorFactory({ ...rgb, a: value / 100 }).toHexString();
                            onEditRow(record, { model_color: color, default_opacity: value });
                        }}
                        onStep={(value: any) => {
                            const rgb = new ColorFactory(cfg.model_color || "#999999").toRgb();
                            const color = new ColorFactory({ ...rgb, a: value / 100 }).toHexString();
                            onEditRow(record, { model_color: color, default_opacity: value });
                        }}
                    />
                );
            },
        },
        {
            title: "层级",
            dataIndex: "level_id",
            render: (text: string, record: any) => {
                const cfg = record.config || {};
                return (
                    <EditSelect
                        onChange={(value: any) => {
                            onEditRow(record, { level_id: value });
                        }}
                        placeholder="选择层级"
                        className="level-sel"
                        defaultValue={cfg.level_name}
                        onClick={() => {
                            updateData((draft) => {
                                if (!draft.part.id) {
                                    message.warning("请先选择重建部位！");
                                } else if (draft.levelOptions.length === 0) {
                                    message.warning("改部位下没有可选层级！");
                                }
                            });
                        }}
                        options={data.levelOptions}
                        allowClear={true}
                    />
                );
            },
        },
    ];

    type FieldStudy = {
        part_id?: string;
        patient_id?: string;
        patient_name?: string;
        accession_number?: string;
        gender?: string;
        age?: number;
        hospital?: string;
        department?: string;
        study_date?: string;
    };

    return (
        <Modal
            confirmLoading={data.commting}
            width={900}
            centered={true}
            title="调整数据"
            open={isOpen}
            onOk={() => studyForm.submit()}
            onCancel={() => {
                onCancel();
                studyForm.resetFields();
            }}>
            <Spin spinning={data.loading}>
                <Form labelCol={{span: 6}} className="dialog" form={studyForm} onFinish={onSubmitStudy}>
                    <div className="row">
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="患者姓名" name="patient_name">
                                <TrimInput />
                            </Form.Item>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="检查号" name="accession_number">
                                <TrimInput />
                            </Form.Item>
                        </Col>
                    </div>
                    <div className="row">
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="患者号" name="patient_id">
                                <TrimInput />
                            </Form.Item>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="性别" name="gender">
                                <Select
                                    options={[
                                        { value: "M", label: "女" },
                                        { value: "F", label: "男" },
                                    ]}
                                    allowClear={true}
                                />
                            </Form.Item>
                        </Col>
                    </div>
                    <div className="row">
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="年龄" name="age">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="重建部位" name="part_id">
                                <Select
                                    options={data.bodyOptions}
                                    allowClear={true}
                                    onChange={(value) => {
                                        reload(value, "");
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </div>
                    <div className="row">
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="医院" name="hospital">
                                <TrimInput />
                            </Form.Item>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="科室" name="department">
                                <TrimInput />
                            </Form.Item>
                        </Col>
                    </div>
                    <div className="row">
                        <Col span={11}>
                            <Form.Item<FieldStudy> label="检查日期" name="study_date">
                                <DatePicker format={DATE} />
                            </Form.Item>
                        </Col>
                    </div>

                    <DndContext onDragEnd={onDragEnd}>
                        <SortableContext items={data.rows.map((it: any) => it.id)}>
                            <Table
                                components={{
                                    body: {
                                        row: Row,
                                    },
                                }}
                                pagination={false}
                                rowKey="id"
                                columns={columns}
                                dataSource={data.rows}
                            />
                        </SortableContext>
                    </DndContext>
                </Form>
            </Spin>
        </Modal>
    );
}
