import { useEffect, useState } from "react";

import { RowModal } from "components/RowModal";
import { useUploadModal } from "auth-app/pc/hooks/operate-list/table/useUploadModal";
import { FileUpload } from "../../operate-upload/upload";
import { useHttp } from "utils/http";
import { useUrlQueryParam } from "utils/hooks/use-url-query-param";
import { UPLOAD_UPDATE } from "lib/api";

export const UploadModal = () => {
  const [isOpen, , close] = useUploadModal();
  const [folderId, setFolderId] = useState<string | number>();
  const [{ uploadKey }] = useUrlQueryParam(["uploadKey"]);

  const client = useHttp();

  const onOkHandle = () => {
    if (folderId && uploadKey) {
      client(UPLOAD_UPDATE(folderId, uploadKey));
    }
    close();
  };

  useEffect(() => {
    if (isOpen) {
      setFolderId(undefined);
    }
  }, [isOpen]);

  return (
    <RowModal
      bodyStyle={{
        minHeight: "24rem",
      }}
      title="更新"
      open={isOpen}
      onCancel={close}
      onOk={onOkHandle}
    >
      <FileUpload
        style={{ width: "95%" }}
        limitOne={true}
        isUpdate={true}
        setFolderId={setFolderId}
      />
    </RowModal>
  );
};
