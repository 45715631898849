import { LOGIN, LOGIN_DINGTALK, TOKEN_LOGIN } from "lib/api";
import { LoginForm, User } from "lib/interface";
import { localStorageKey } from "utils/const";
import { http } from "utils/http";

export const getToken = () => window.localStorage.getItem(localStorageKey);

export const setToken = (token: string | null) => {
  window.localStorage.setItem(localStorageKey, token || "");
  return token;
};

const handleUserResponse = (user: User) => {
  window.localStorage.setItem(localStorageKey, user.token || "");
  return user;
};

export const login = async ({ username, password }: LoginForm) => {
  return http(`${LOGIN}?username=${username}&password=${password}`, {
    method: "POST",
  }).then(async (res) => {
    return handleUserResponse(res);
  });
};

const handleAutoUserResponse = ({
  user,
  token,
}: {
  user: User;
  token: string;
}) => {
  window.localStorage.setItem(localStorageKey, token || "");
  user["token"] = token;
  return user;
};

export const loginAuto = async (token: string) => {
  return http(`${TOKEN_LOGIN}?token=${token}`)
    .then(async (res) => {
      return handleAutoUserResponse({ user: res, token });
    })
    .catch((e) => {
      throw e;
    });
};

const handleDilngUserResponse = (user: User) => {
  window.localStorage.setItem(localStorageKey, user.token || "");
  return user;
};

export const loginDingtalk = async (code: string) => {
  return http(`${LOGIN_DINGTALK}?authCode=${code}`)
    .then(async (res) => {
      return handleDilngUserResponse(res);
    })
    .catch((e) => {
      throw e;
    });
};

export const logout = async () => {
  window.localStorage.removeItem(localStorageKey);
  window.location.replace(window.location.origin);
};
