import { ComponentProps, FC } from "react";
import { Button } from "antd";

type ButtonNoSpaceType = ComponentProps<typeof Button>;
interface ButtonNoSpaceProps extends ButtonNoSpaceType {}

export const ButtonNoSpace: FC<ButtonNoSpaceProps> = ({ ...props }) => {
  return (
    <Button
      {...props}
      style={{
        padding: 0,
        margin: 0,
      }}
    />
  );
};
