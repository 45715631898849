import {
  ComponentProps,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { ConfigurationType, FileType } from "lib/interface";
import { BlockColorPicker } from "components/BlockColorPicker";
import { message, Table } from "antd";
import { toNumber } from "utils";
import { EditInput } from "components/EditInput";
import { ColumnsType } from "antd/es/table";
import { EditInputNumber } from "components/EditInputNumber";
import { EditSelect } from "components/EditSelect";
import { defaultColor } from "utils/const";
import { ColorFactory } from "antd/es/color-picker/color";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/use-async";
import { GET_HIERARCHY } from "lib/api";

export type DataSourceType = {
  key: React.Key;
  id: number;
  file_name: string;
  display_name?: string;
  model_color?: string;
  default_opacity?: string;
  hierarchy?: string;
};

type ModifyStlType = ComponentProps<typeof Table<DataSourceType>>;
type ModifyStlProps = ModifyStlType & {
  files?: FileType[];
  configurations?: ConfigurationType[];
  body_part: string | null;
  dataSource: readonly DataSourceType[];
  setDataSource: Dispatch<SetStateAction<readonly DataSourceType[]>>;
};

export const ModifyStl: FC<ModifyStlProps> = ({ ...props }) => {
  const { files, configurations, body_part, dataSource, setDataSource } = props;
  const [isChanged, setIsChanged] = useState(false);
  const client = useHttp();

  const {
    run: getHierarchyList,
    data: hierarchyList,
    // isIdle: hierarchyListIsIdle,
    isLoading: gettinghierarchyList,
  } = useAsync<string[]>();

  const handleUpdate = (
    record: DataSourceType,
    value: Partial<DataSourceType>,
  ) => {
    let tmpData = dataSource.map((i) => {
      if (i.id === record.id) {
        return { ...i, ...value };
      }
      return i;
    });
    setDataSource(tmpData);
  };

  useEffect(() => {
    setIsChanged(false);
    let tmpdata: DataSourceType[] = [];
    files?.forEach((v, i) => {
      tmpdata.push({
        key: v.id,
        id: v.id,
        file_name: v.file_name,
        display_name: configurations?.[i]?.display_name,
        model_color: configurations?.[i]?.model_color,
        default_opacity: configurations?.[i]?.default_opacity,
        hierarchy: configurations?.[i]?.hierarchy || undefined,
      });
    });
    setDataSource(tmpdata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, configurations]);

  useEffect(() => {
    if (body_part) {
      getHierarchyList(
        client(GET_HIERARCHY, { data: { body_part: body_part } }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body_part]);

  const Loading = gettinghierarchyList;

  const columns: ColumnsType<DataSourceType> = [
    {
      title: "文件名称",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "展示名称",
      dataIndex: "display_name",
      key: "display_name",
      render: (value, record) => {
        return (
          <EditInput
            placeholder="填写名称"
            value={value}
            onChange={(v) => {
              handleUpdate(record, { display_name: v.target.value });
            }}
          />
        );
      },
    },
    {
      title: "颜色",
      dataIndex: "model_color",
      key: "model_color",
      width: "4rem",
      render: (value, record) => {
        return (
          <BlockColorPicker
            value={value}
            onChange={(c) => {
              handleUpdate(record, {
                model_color: c.toHexString(),
                default_opacity: Math.round(
                  toNumber(c.toRgb().a) * 100,
                ).toString(),
              });
            }}
          />
        );
      },
    },
    {
      title: "默认透明度(%)",
      dataIndex: "default_opacity",
      key: "default_opacity",
      width: "8rem",
      render: (value, record) => {
        return (
          <EditInputNumber
            min={0}
            max={100}
            step={1}
            value={toNumber(
              value !== undefined && value !== null
                ? value
                : isChanged
                ? value
                : 50,
            )}
            onChange={(v) => {
              setIsChanged(true);
              let coolr = new ColorFactory(
                record?.model_color || defaultColor,
              ).toRgb();
              coolr.a = (v as number) / 100;
              handleUpdate(record, {
                default_opacity: v?.toString(),
                model_color: new ColorFactory(coolr).toHexString(),
              });
            }}
          />
        );
      },
    },
    {
      title: "层级",
      dataIndex: "hierarchy",
      key: "hierarchy",
      render: (value, record) => {
        return (
          <EditSelect
            placeholder="选择层级"
            allowClear
            disabled={body_part ? false : true}
            onClick={() => {
              if (!body_part) message.warning("请先选择重建部位");
            }}
            style={{ minWidth: "6rem" }}
            options={hierarchyList?.map((b) => ({ label: b, value: b }))}
            value={value}
            onChange={(v) => {
              handleUpdate(record, { hierarchy: v as string });
            }}
          />
        );
      },
    },
  ];

  return (
    <Table<DataSourceType>
      loading={Loading}
      {...props}
      style={{ width: "100%" }}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
    />
  );
};
