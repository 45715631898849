import { CSSProperties, Fragment, MutableRefObject, useState, useCallback } from "react";
import { Button, message, Space } from "antd";
import type { ActionType, ColumnsState, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { SortOrder } from "antd/es/table/interface";
import { useImmer } from "use-immer";
import { QrModal } from "../qr-modal";
import { useQrModal } from "auth-app/pc/hooks/operate-list/table/useQrModal";
import { useUploadModal } from "auth-app/pc/hooks/operate-list/table/useUploadModal";
import { UploadModal } from "../upload-modal";
import { ModifyModal } from "../modify-modal";
import { useModifyModal } from "auth-app/pc/hooks/operate-list/table/useModifyModal";
import { useGetFile, useHttp } from "utils/http";
import { ListDataType } from "lib/interface";
import { EXPORT_LIST, GET_LIST, GET_QR, UPDATE_STUDY } from "lib/api";
import { ButtonNoSpace } from "components/ButtonNoSpace";
import { DateFormat } from "utils";
import { EditCallbackInput } from "components/EditCallbackInput";
import { LayoutMinWidth, prefixH5 } from "utils/const";
import AlterData from "../alter-data";

const getSortParam = (sorter: Record<string, SortOrder>) => {
    const key = Object.keys(sorter)?.[0];
    const value = sorter[key];

    if (value === "ascend") {
        return key.toString();
    } else {
        return `-${key.toString()}`;
    }
};

const DlgNone = "";
const DlgAlterData = "DlgAlterData";

export const DataListTable = ({ actionRef, style }: { actionRef: MutableRefObject<ActionType | undefined>; style?: CSSProperties }) => {
    const [, qrModalOpen] = useQrModal();
    const [, uploadModalOpen] = useUploadModal();
    const [, modifyModalOpen] = useModifyModal();

    const [searchProps, setSearchProps] = useState<{ [k in string]: any }>();

    const [data, updateData] = useImmer({
        target: {} as any,
        dlgType: DlgNone,
    });

    const client = useHttp();
    const fileClient = useGetFile();

    const [columnsStateMap, setColumnsStateMap] = useState<Record<string, ColumnsState>>({
        patient_id: { show: false },
        gender: { show: false },
        age: { show: false },
        department: { show: false },
    });

    const handleUpdate = (id: number) => {
        uploadModalOpen(id);
    };

    const fnGoEdit = useCallback(
        (record: any) => {
            updateData((draft) => {
                draft.target = record;
                draft.dlgType = DlgAlterData;
            });
        },
        [updateData],
    );

    const columns: ProColumns<ListDataType>[] = [
        {
            title: "编号",
            search: false,
            align: "center",
            width: "4rem",
            hideInSetting: true,
            render: (_, __, index) => index + 1,
        },
        {
            title: "患者姓名",
            order: 11,
            dataIndex: "patient_name",
        },
        {
            title: "检查号",
            order: 10,
            dataIndex: "accession_number",
            filters: true,
            onFilter: true,
        },
        {
            title: "患者号",
            order: 9,
            dataIndex: "patient_id",
            search: false,
        },
        {
            title: "性别",
            order: 8,
            dataIndex: "gender",
            search: false,
            valueEnum: {
                F: {
                    text: "F",
                    status: "F",
                },
                M: {
                    text: "M",
                    status: "M",
                },
            },
        },
        {
            title: "年龄",
            order: 7,
            dataIndex: "age",
            search: false,
        },
        {
            title: "重建部位",
            order: 6,
            dataIndex: "body_part",
            search: false,
            sorter: true,
        },
        {
            title: "医院",
            order: 5,
            dataIndex: "hospital",
            search: false,
            sorter: true,
        },
        {
            title: "科室",
            order: 4,
            dataIndex: "department",
            search: false,
        },
        {
            title: "检查日期",
            order: 3,
            dataIndex: "study_date",
            valueType: "date",
            search: false,
            sorter: true,
        },
        {
            title: "上传时间",
            order: 2,
            dataIndex: "create_time",
            valueType: "dateTime",
            search: false,
            width: "10rem",
            sorter: true,
        },
        {
            title: "上传日期",
            hideInTable: true,
            valueType: "dateRange",
            dataIndex: "update_date",
        },
        {
            title: "备注",
            order: 1,
            dataIndex: "note",
            search: false,
            render: (value, record) => {
                const { id } = record;
                return (
                    <EditCallbackInput
                        defaultValue={value === "-" ? "" : value?.toString()}
                        need_callback={"true"}
                        callback_url={UPDATE_STUDY(id)}
                        callback_method="PUT"
                        action_ref={actionRef}
                    />
                );
            },
        },
        {
            title: "操作",
            order: 0,
            valueType: "option",
            key: "option",
            width: 300,
            align: "center",
            render: (_, record) => {
                const { id, body_part } = record;

                return (
                    <Space>
                        <ButtonNoSpace
                            key="url"
                            type="link"
                            onClick={() => {
                                if (!body_part) {
                                    message.warning("请先选择部位");
                                } else {
                                    client(GET_QR, { data: { id: id } })
                                        .then((res) => {
                                            window.open(`/h5/${prefixH5}/?key=${res.data.key}${res.data.code ? `&code=${res.data.code}` : ""}`, "_blank");
                                        })
                                        .catch(() => message.error("无法打开详情，请重试"));
                                }
                            }}>
                            <span
                                style={{
                                    color: body_part ? undefined : "grey",
                                }}>
                                详情
                            </span>
                        </ButtonNoSpace>
                        <ButtonNoSpace
                            key="qr"
                            type="link"
                            onClick={() => {
                                if (!body_part) {
                                    message.warning("请先选择部位");
                                } else {
                                    qrModalOpen(record.id);
                                }
                            }}>
                            <span
                                style={{
                                    color: body_part ? undefined : "grey",
                                }}>
                                导出二维码
                            </span>
                        </ButtonNoSpace>
                        <ButtonNoSpace
                            key="report"
                            type="link"
                            onClick={() => {
                                if(record.body_part === "胸外"){
                                    window.open(`/html/dm-report/?study_id=${record.id}`, "_blank");
                                }else{
                                    window.open(`/report/dm/?id=${record.id}`, "_blank");
                                }
                            }}>
                            导出报告
                        </ButtonNoSpace>
                        <ButtonNoSpace
                            key="mod"
                            type="link"
                            onClick={() => {
                                // modifyModalOpen(record.id)
                                fnGoEdit(record);
                            }}>
                            调整数据
                        </ButtonNoSpace>
                        <ButtonNoSpace key="update" type="link" onClick={() => handleUpdate(record.id)}>
                            更新
                        </ButtonNoSpace>
                    </Space>
                );
            },
        },
    ];

    return (
        <Fragment>
            <ProTable<ListDataType>
                style={{
                    ...style,
                    minWidth: LayoutMinWidth,
                }}
                columns={columns}
                actionRef={actionRef}
                cardBordered
                request={async (params, sorter) => {
                    // 表单搜索项会从 params 传入，传递给后端接口。
                    const { update_date } = params;
                    let start_date = update_date?.[0] ? dayjs(update_date?.[0]).format(DateFormat) : undefined;
                    let end_date = update_date?.[1] ? dayjs(update_date?.[1]).format(DateFormat) : undefined;
                    let queryParams = {
                        number: params.pageSize,
                        page: params.current,
                        patient_name: params.patient_name,
                        accession_number: params.accession_number,
                        start_date,
                        end_date,
                        sort_by: sorter && JSON.stringify(sorter) !== "{}" ? getSortParam(sorter) : undefined,
                    };
                    try {
                        const res = await client(GET_LIST, {
                            data: { ...queryParams },
                        });
                        setSearchProps(queryParams);
                        return {
                            data: res.list,
                            total: res.total,
                            success: true,
                        };
                    } catch {
                        return { success: false };
                    }
                }}
                columnsState={{
                    // persistenceKey: "table-list",
                    // persistenceType: "localStorage",
                    value: columnsStateMap,
                    onChange: setColumnsStateMap,
                }}
                rowKey="id"
                search={{
                    labelWidth: "auto",
                    searchText: "搜索",
                    span: 6,
                }}
                options={{
                    setting: {
                        listsHeight: 400,
                    },
                }}
                form={{
                    // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
                    syncToUrl: (values, type) => {
                        if (type === "get") {
                            return {
                                ...values,
                                created_at: [values.startTime, values.endTime],
                            };
                        }
                        return values;
                    },
                }}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                    showQuickJumper: true,
                }}
                dateFormatter="string"
                headerTitle="上传列表"
                toolBarRender={() => [
                    <Button
                        key="output"
                        onClick={() => {
                            client(EXPORT_LIST, {
                                data: {
                                    patient_name: searchProps?.patient_name,
                                    accession_number: searchProps?.accession_number,
                                    start_date: searchProps?.start_date,
                                    end_date: searchProps?.end_date,
                                },
                            }).then(async (res) => {
                                let info: { url: string } = res;
                                fileClient(`/api/files/${info.url}`)
                                    .then((blob) => {
                                        const url = URL.createObjectURL(blob);
                                        const link = document.createElement("a");
                                        link.href = url;
                                        link.download = `${info.url.replace("csv/", "")}`;
                                        link.click();
                                        URL.revokeObjectURL(url);
                                    })
                                    .catch((e) => message.error(e?.errorMsg || "未知错误"));
                            });
                        }}>
                        导出数据
                    </Button>,
                ]}
            />
            <QrModal />
            <UploadModal />
            <ModifyModal actionRef={actionRef} />
            <AlterData
                isOpen={data.dlgType === DlgAlterData}
                target={data.target}
                onOk={() => {
                    updateData((draft) => {
                        draft.dlgType = DlgNone;
                    });
                    actionRef.current?.reload();
                }}
                onCancel={() => {
                    updateData((draft) => {
                        draft.dlgType = DlgNone;
                    });
                }}
            />
        </Fragment>
    );
};
