import { Modal } from "antd";
import { ComponentProps, FC } from "react";

type RowModalType = ComponentProps<typeof Modal>;

// interface RowModalProps extends Omit<RowModalType, "bodyStyle"> {
//   bodyStyle: React.CSSProperties;
// };

export const RowModal: FC<RowModalType> = ({ ...props }) => {
  return (
    <Modal
      // destroyOnClose
      centered
      maskClosable={false}
      width={"48rem"}
      style={{
        minWidth: "48rem",
      }}
      {...props}
      bodyStyle={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        ...props.bodyStyle,
      }}
    >
      {props.children}
    </Modal>
  );
};
