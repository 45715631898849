import { DefaultOptionType } from "antd/es/select";
import { useEffect, useRef } from "react";

export const isFalsy = (value: unknown): boolean =>
  value === 0 || value === "" ? false : !value; // 0 和 空字符串认为有效

export const cleanObject = (object: { [key: string]: any }) => {
  const result = { ...object };
  Object.keys(result).forEach((key) => {
    const value = result[key];
    if (isFalsy(value)) {
      delete result[key];
    }
  });
  return result;
};

export const toNumber = (value: unknown) =>
  isNaN(Number(value)) ? 0 : Number(value);

export const useMountedRef = () => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  return mountedRef;
};

export const GenderList: DefaultOptionType[] = [
  { label: "男", value: "M" },
  { label: "女", value: "F" },
];

export const DateFormat = "YYYY-MM-DD";

export const NormalizeForm = (value: any) => {
  if (value === null || value === undefined) return undefined;
  return value;
};

export const initObj = (object: { [key: string]: any }) => {
  const result = { ...object };
  Object.keys(result).forEach((key) => {
    const value = result[key];
    if (value === null || value === undefined || value === "") {
      result[key] = "";
    }
  });
  return result;
};
