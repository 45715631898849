import { Modal, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import * as api from "../api";
import { trim } from "../util-view";

import "./add-part.css";

const TrimInput = trim(Input);

type FieldType = {
    name?: string;
};

export default function View({ onOk, onCancel, isOpen }: any) {
    const [data, updateData] = useImmer({
        confirmLoading: false,
    });
    const [formRef] = Form.useForm();

    const onSubmit = (values: any) => {
        updateData((draft) => {
            draft.confirmLoading = true;
        });
        api.update_part(values).then((resp) => {
            const fnMsg = resp.code === 1 ? message.info : message.warning;
            const msg = resp.msg || (resp.code === 1 ? "请求成功！" : "请求失败！");
            fnMsg(msg);
            updateData((draft) => {
                draft.confirmLoading = false;
            });
            if (resp.code === 1) {
                formRef.resetFields();
                onOk();
            }
        });
    };

    useEffect(() => {
        updateData((draft) => {
            draft.confirmLoading = false;
        });
    }, [updateData]);

    return (
        <Modal centered={true} title="新增部位" open={isOpen} confirmLoading={data.confirmLoading} onOk={() => formRef.submit()} onCancel={onCancel}>
            <Form labelCol={{span: 6}} form={formRef} onFinish={onSubmit} className="h-center">
                <Form.Item<FieldType> className="form-item" label="部位" name="name" rules={[{ required: true, message: "请输入部位名称!" }]}>
                    <TrimInput />
                </Form.Item>
            </Form>
        </Modal>
    );
}
