import { ReactNode } from "react";
import { Row } from "antd";

export const ModalLabelWidth = "12rem";

export const BaseRow = ({ children }: { children: ReactNode }) => (
  <Row
    style={{ display: "flex", justifyContent: "space-between", width: "38rem" }}
  >
    {children}
  </Row>
);

export const BaseLabel = ({ children }: { children: ReactNode }) => (
  <span style={{ width: "5rem" }}>{children}</span>
);
