import { Dispatch, SetStateAction } from "react";
import { DingtalkCircleFilled } from "@ant-design/icons";

import lockSvg from "assets/login/switch-lock.svg";

export const LoginSwitch = ({
  isPwdLogin,
  setIsPwdLogin,
}: {
  isPwdLogin: boolean;
  setIsPwdLogin: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="login-switch-container">
      <div onClick={() => setIsPwdLogin(!isPwdLogin)}>
        {isPwdLogin ? (
          <DingtalkCircleFilled
            style={{
              fontSize: 40,
              background: "#FFF",
              color: "rgba(50, 150, 250, 1)",
              borderRadius: "50%",
            }}
          />
        ) : (
          <div
            style={{
              height: 40,
              width: 40,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(11, 195, 210, 1)",
            }}
          >
            <img src={lockSvg} alt="" style={{ height: 22, fill: "#FFFFFF" }} />
          </div>
        )}
      </div>
    </div>
  );
};
