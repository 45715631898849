import qs from "qs";
import * as auth from "auth-provider";
import { message } from "antd";
import { ErrorMsg } from "lib/interface";

interface Config extends RequestInit {
  token?: string | null;
  data?: object;
}
export const http = async (
  endpoint: string,
  { data, token, headers, ...customConfig }: Config = {}
) => {
  const config = {
    method: "GET", // 默认GET，后面会被customeConfig覆盖
    headers: {
      Authorization: token ? `jwt ${token}` : "",
      "Content-Type": data ? "application/json" : "",
      ...headers,
    },
    ...customConfig,
  };

  if (config.method.toUpperCase() === "GET") {
    if (data) {
      if (endpoint.endsWith("/")) {
        endpoint += `?${qs.stringify(data)}`;
      } else {
        endpoint += `?${qs.stringify(data)}`;
      }
    }
  } else {
    config.body = JSON.stringify(data || {});
  }

  return window
    .fetch(`${endpoint}`, config)
    .then(async (response) => {
      if (response.ok) {
        try {
          const data = await response.json();
          return data;
        } catch (e) {
          console.error(e);
          return Promise.reject({ errorMsg: "解析失败" });
        }
      } else {
        return Promise.reject(response);
      }
    })
    .catch(async (e) => {
      let msg: ErrorMsg;
      try {
        msg = await e.json();
      } catch {
        msg = e;
      }
      console.error(msg);
      if (msg.errorCode !== 401) {
        // 401登录错误有页面提示, 不需要全局提示
        message.error(msg?.errorMsg || "未知错误，请联系管理员或刷新重试！");
      }
      throw e;
    });
};

export const useHttp = () => {
  const token = auth.getToken();
  return (...[endpoint, config]: Parameters<typeof http>) =>
    http(endpoint, { ...config, token });
};

export const postFile = async (
  endpoint: string,
  { token, ...customConfig }: Config = {}
) => {
  const config = {
    method: "POST",
    headers: {
      Authorization: token ? `jwt ${token}` : "",
    },
    ...customConfig,
  };

  return window
    .fetch(`${endpoint}`, config)
    .then(async (response) => {
      if (response.ok) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    })
    .catch(async (e) => {
      let msg: ErrorMsg;
      try {
        msg = await e.json();
      } catch {
        msg = e;
      }
      console.error(msg);
      message.error(msg?.errorMsg || "未知错误，请联系管理员或刷新重试！");
      throw e;
    });
};

export const usePostFile = () => {
  const token = auth.getToken();
  return (...[endpoint, config]: Parameters<typeof postFile>) =>
    postFile(endpoint, { ...config, token });
};

export const getFile = async (
  endpoint: string,
  { data, token, headers, ...customConfig }: Config = {}
) => {
  const config = {
    method: "GET", // 默认GET，后面会被customeConfig覆盖
    headers: {
      Authorization: token ? `jwt ${token}` : "",
      "Content-Type": data ? "application/json" : "",
      ...headers,
    },
    ...customConfig,
  };

  if (config.method.toUpperCase() === "GET") {
    if (data) {
      if (endpoint.endsWith("/")) {
        endpoint += `?${qs.stringify(data)}`;
      } else {
        endpoint += `?${qs.stringify(data)}`;
      }
    }
  } else {
    config.body = JSON.stringify(data || {});
  }

  return window
    .fetch(`${endpoint}`, config)
    .then(async (response) => {
      if (response.ok) {
        try {
          const data = await response.blob();
          return data;
        } catch (e) {
          console.error(e);
          return Promise.reject({ errorMsg: "解析失败" });
        }
      } else {
        return Promise.reject(response);
      }
    })
    .catch(async (e) => {
      let msg: ErrorMsg;
      try {
        msg = await e.json();
      } catch {
        msg = e;
      }
      console.error(msg);
      if (msg.errorCode !== 401) {
        // 401登录错误有页面提示, 不需要全局提示
        message.error(msg?.errorMsg || "未知错误，请联系管理员或刷新重试！");
      }
      throw e;
    });
};

export const useGetFile = () => {
  const token = auth.getToken();
  return (...[endpoint, config]: Parameters<typeof http>) =>
    getFile(endpoint, { ...config, token });
};
