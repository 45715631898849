import { InputNumber } from "antd";
import { ComponentProps, FC, useState } from "react";
type EditInputNumberType = ComponentProps<typeof InputNumber>;
interface EditInputNumberProps extends EditInputNumberType {}

export const EditInputNumber: FC<EditInputNumberProps> = ({ ...props }) => {
  const [isEditting, setIsEditting] = useState(false);

  return (
    <InputNumber
      {...props}
      bordered={isEditting}
      onBlur={() => setIsEditting(false)}
      onFocus={() => setIsEditting(true)}
    />
  );
};
