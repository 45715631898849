import { Select } from "antd";
import { ComponentProps, FC, useState } from "react";

type EditSelectType = ComponentProps<typeof Select>;
interface EditSelectProps extends EditSelectType {}

export const EditSelect: FC<EditSelectProps> = ({ ...props }) => {
  const [isEditting, setIsEditting] = useState(false);

  return (
    <Select
      {...props}
      bordered={isEditting}
      showArrow={isEditting}
      onBlur={() => setIsEditting(false)}
      onFocus={() => setIsEditting(true)}
    />
  );
};
