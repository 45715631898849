import { ComponentProps, Dispatch, FC, SetStateAction, useEffect } from "react";
import { DatePicker, Form, Input, InputNumber, Select, Spin } from "antd";
import dayjs from "dayjs";

import { BaseLabel, BaseRow, ModalLabelWidth } from "lib";
import { ListDataType } from "lib/interface";
import { DateFormat, GenderList } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/use-async";
import { useMount } from "utils/hooks/use-mount";
import { GET_BODY_PART } from "lib/api";

const { Item } = Form;

export interface infoSubType
  extends Omit<ListDataType, "files" | "configurations"> {
  data: ListDataType | null;
}

type ModifyInfoType = ComponentProps<typeof Form>;
type ModifyInfoProps = ModifyInfoType & {
  loading: boolean;
  data: ListDataType | null;
  setBodyPart: Dispatch<SetStateAction<string | null>>;
  handleUpdateBodyPart: (bodyPart: string) => void;
};

export const ModifyInfo: FC<ModifyInfoProps> = ({ ...props }) => {
  const { loading, form, data, setBodyPart, handleUpdateBodyPart } = props;
  const client = useHttp();

  const {
    run: getBodyPart,
    data: body_part,
    isIdle: bodyPartIsIdle,
    isLoading: gettingBodyPart,
  } = useAsync<string[]>();

  useEffect(() => {
    form?.setFieldsValue({
      ...data,
      study_date: data?.study_date ? dayjs(data?.study_date) : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useMount(() => {
    getBodyPart(client(GET_BODY_PART));
  });

  const Loading = bodyPartIsIdle || gettingBodyPart || loading;

  return (
    <Spin spinning={Loading}>
      <Form {...props}>
        <BaseRow>
          <Item label={<BaseLabel>患者姓名</BaseLabel>} name="patient_name">
            <Input style={{ width: ModalLabelWidth }} />
          </Item>
          <Item label={<BaseLabel>检查号</BaseLabel>} name="accession_number">
            <Input style={{ width: ModalLabelWidth }} />
          </Item>
        </BaseRow>
        <BaseRow>
          <Item label={<BaseLabel>患者号</BaseLabel>} name="patient_id">
            <Input style={{ width: ModalLabelWidth }} />
          </Item>
          <Item label={<BaseLabel>性别</BaseLabel>} name="gender">
            <Select style={{ width: ModalLabelWidth }} options={GenderList} />
          </Item>
        </BaseRow>
        <BaseRow>
          <Item label={<BaseLabel>年龄</BaseLabel>} name="age">
            <InputNumber style={{ width: ModalLabelWidth }} />
          </Item>
          <Item label={<BaseLabel>重建部位</BaseLabel>} name="body_part">
            <Select
              style={{ width: ModalLabelWidth }}
              options={body_part?.map((b) => ({ label: b, value: b }))}
              onChange={(v) => {
                setBodyPart(v);
                handleUpdateBodyPart(v);
              }}
            />
          </Item>
        </BaseRow>
        <BaseRow>
          <Item label={<BaseLabel>医院</BaseLabel>} name="hospital">
            <Input style={{ width: ModalLabelWidth }} />
          </Item>
          <Item label={<BaseLabel>科室</BaseLabel>} name="department">
            <Input style={{ width: ModalLabelWidth }} />
          </Item>
        </BaseRow>
        <BaseRow>
          <Item label={<BaseLabel>检查日期</BaseLabel>} name="study_date">
            <DatePicker
              allowClear={false}
              style={{ width: ModalLabelWidth }}
              format={DateFormat}
            />
          </Item>
        </BaseRow>
      </Form>
    </Spin>
  );
};
