import { useState } from "react";
import { Divider, Layout } from "antd";

import { LoginPasswordForm } from "./pwd-login";
import { DingtalkQrLogin } from "./dingtalk-qr-login";
import { LoginWelcomChar } from "./welcom";
import { LoginRightContent } from "./right-content";
import { Copyright } from "./copyright";
import { LoginSwitch } from "./login-swtich";

import logo from "assets/menu-large.png";

import "./index.css";

const { Sider } = Layout;

export const Login = () => {
  const [isPwdLogin, setIsPwdLogin] = useState(true);

  return (
    <Layout className="login-page">
      <Sider className="login-sider" width={"30rem"}>
        <img className="sider-logo" src={logo} alt="infer-logo" />
        <LoginWelcomChar />
        {isPwdLogin ? <LoginPasswordForm /> : <DingtalkQrLogin />}
        <Divider plain style={{ marginTop: "2rem" }}>
          或
        </Divider>
        <LoginSwitch isPwdLogin={isPwdLogin} setIsPwdLogin={setIsPwdLogin} />
        <Copyright />
      </Sider>
      <LoginRightContent />
    </Layout>
  );
};
