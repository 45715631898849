import React, { Fragment } from "react";
import "./App.css";
import Home from "auth-app/pc/pages";
import { Login } from "login";
import { useAuth } from "context/auth-context";
import { LoadingPage } from "components/LoadingPage";

const DmApp: React.FC = () => {
  const { user, isIdle, isLogining } = useAuth();

  const Loading = isIdle || isLogining;

  return (
    <Fragment>
      {Loading ? <LoadingPage /> : user ? <Home /> : <Login />}
    </Fragment>
  );
};

export default DmApp;
