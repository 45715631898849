import { useUrlQueryParam } from "utils/hooks/use-url-query-param";

export const useUploadModal = () => {
  const [{ uploadModalOpen }, setUploadModalOpen] = useUrlQueryParam([
    "uploadModalOpen",
    "uploadKey",
  ]);

  const open = (id?: number) =>
    setUploadModalOpen({ uploadModalOpen: true, uploadKey: id });
  const close = () =>
    setUploadModalOpen({ uploadModalOpen: undefined, uploadKey: undefined });

  return [uploadModalOpen === "true", open, close] as const;
};
