import { useCallback, useEffect } from "react";
import { Button, message, Modal, Spin } from "antd";
import { useImmer } from "use-immer";
import { Tabs } from "antd";

import AddPrt from "./add-part";
import AddLevel from "./add-level";
import AddConfig from "./add-config";
import ConfigList from "./config-list";
import * as api from "../api";

import "./index.css";

export default function View() {
    const [data, updateData] = useImmer({
        spinning: false,
        isOpenPart: false,
        isOpenLevel: false,
        isOpenConfig: false,
        bodyParts: [] as any,
        allData: [] as any[],
        target: {} as any,
    });

    const openAddPart = (isOpen: boolean) => {
        updateData((draft) => {
            draft.isOpenPart = isOpen;
        });
    };

    const openAddLevel = useCallback(
        (record: any) => {
            updateData((draft) => {
                draft.isOpenLevel = record !== null;
                draft.target = record || {};
            });
        },
        [updateData],
    );

    const openAddConfig = useCallback(
        (record: any) => {
            updateData((draft) => {
                draft.isOpenConfig = record !== null;
                if (record) {
                    draft.target = record;
                }
            });
        },
        [updateData],
    );

    const reload = useCallback(() => {
        updateData((draft) => {
            draft.spinning = true;
        });
        api.list_conf_tree().then((resp) => {
            updateData((draft) => {
                draft.spinning = false;
                draft.allData = resp.rows;
                draft.bodyParts = resp.bodyParts;
            });
        });
    }, [updateData]);

    const fnGoDelete = useCallback(
        (record: any) => {
            updateData((draft) => {
                draft.target = record;
            });
            Modal.confirm({
                title: record.leaf ? "确认要删除规则么？" : "确认要删除层级么？",
                centered: true,
                onOk: () => {
                    const fnDelete = record.leaf ? api.delete_conf : api.delete_level;
                    fnDelete(record.id).then((resp) => {
                        const fnMsg = resp.code === 1 ? message.info : message.warning;
                        const msg = resp.msg || (resp.code === 1 ? "请求成功！" : "请求失败！");
                        fnMsg(msg);
                        if (resp.code === 1) {
                            reload();
                        }
                    });
                },
            });
        },
        [updateData, reload],
    );

    const fnGoEdit = useCallback(
        (record: any) => {
            if (record.leaf) {
                openAddConfig(record);
            } else {
                openAddLevel(record);
            }
        },
        [openAddLevel, openAddConfig],
    );

    const fnOnOrder = (newData: any[]) => {
        updateData((draft) => {
            draft.spinning = true;
            draft.allData = newData;
        });
        const trees = [] as any[];
        for (let tree of newData) {
            if (tree.leaf) {
                trees.push({ id: tree.id, leaf: tree.leaf });
            } else {
                const children = [] as any[];
                for (let conf of tree.children || []) {
                    children.push({ id: conf.id, leaf: conf.leaf });
                }
                trees.push({ id: tree.id, leaf: tree.leaf, children: children });
            }
        }
        api.reorder_conf(trees).then((resp) => {
            updateData((draft) => {
                draft.spinning = false;
            });
            reload();
            const fnMsg = resp.code === 1 ? message.info : message.warning;
            const msg = resp.msg || (resp.code === 1 ? "请求成功！" : "请求失败！");
            fnMsg(msg);
        });
    };

    useEffect(() => {
        reload();
    }, [reload]);

    return (
        <div className="page">
            <div className="header">
                <div className="page-title">三维模型默认配置表</div>
                <div className="header-btns">
                    <Button className="btn" onClick={() => openAddLevel({})}>
                        新增层级
                    </Button>
                    <Button className="btn" onClick={() => openAddPart(true)}>
                        新增部位
                    </Button>
                    <Button className="btn" onClick={() => openAddConfig({})}>
                        新增文件规则
                    </Button>
                </div>
            </div>
            <Spin spinning={data.spinning}>
                <Tabs
                    className="tabs"
                    items={[{ id: "*", name: "全部" }, ...data.bodyParts].map((part: any) => {
                        return {
                            key: part.id,
                            label: part.name,
                            children: <ConfigList bodyPart={part} allData={data.allData} fnGoEdit={fnGoEdit} fnGoDelete={fnGoDelete} fnOnOrder={fnOnOrder} />,
                        } as any;
                    })}
                />
            </Spin>

            <AddPrt
                isOpen={data.isOpenPart}
                onOk={() => {
                    openAddPart(false);
                    reload();
                }}
                onCancel={() => openAddPart(false)}
            />
            <AddLevel
                isOpen={data.isOpenLevel}
                target={data.target}
                onOk={() => {
                    openAddLevel(null);
                    reload();
                }}
                onCancel={() => openAddLevel(null)}
                bodyParts={data.bodyParts}
            />
            <AddConfig
                isOpen={data.isOpenConfig}
                target={data.target}
                onOk={(editInfo: any) => {
                    openAddConfig(null);
                    if (editInfo.id) {
                        reload();
                    } else {
                        // 新增配置时，更新排序。
                        fnOnOrder(data.allData);
                    }
                }}
                onCancel={() => openAddConfig(null)}
                bodyParts={data.bodyParts}
            />
        </div>
    );
}
