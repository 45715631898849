import { useEffect, useState } from "react";
import { Alert, Space, Spin } from "antd";
import { WarningFilled } from "@ant-design/icons";

import { useMount } from "utils/hooks/use-mount";
import * as auth from "auth-provider";
import { DTFrameLoginType } from "./ding-interface";

import "./index.css";

declare global {
  interface Window {
    DTFrameLogin: DTFrameLoginType;
    attachEvent: any;
  }
}

export const DingtalkQrLogin = () => {
  const { DTFrameLogin } = window;

  const [isLoginError, setIsLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authCode, setAuthCode] = useState<string>("");

  useEffect(() => {
    if (authCode) {
      auth
        .loginDingtalk(authCode)
        .then(() => window.location.reload())
        .catch(() => setIsLoginError(true));
    }
  }, [authCode]);

  useMount(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  useMount(() => {
    DTFrameLogin(
      {
        id: "dingtalk-login-qr",
        width: 280,
        height: 280,
      },
      {
        redirect_uri: encodeURIComponent(
          `${window.location.origin}/v1/login/dingtalk`
        ),
        client_id: "dingn4qqgyioyztiirwz",
        scope: "openid",
        response_type: "code",
        prompt: "consent",
      },
      (loginResult) => {
        const { authCode } = loginResult;
        setAuthCode(authCode);
      },
      (errorMsg) => {
        setIsLoginError(true);
        console.error(`Login Error: ${errorMsg}`);
      }
    );
  });

  return (
    <Space className="dingtalk-login-container" direction="vertical" size={14}>
      <Spin spinning={isLoading}>
        <div id="dingtalk-login-qr" style={{ backgroundColor: "#FFFFFF" }} />
      </Spin>
      {isLoginError ? (
        <Alert
          message={
            <Space style={{ color: "#D43645", fontSize: "16px" }}>
              <WarningFilled />
              <span>无权限登录，请联系管理员</span>
            </Space>
          }
          type="error"
          style={{
            textAlign: "center",
            border: "1px solid #D43645",
            backgroundColor: "rgba(212, 54, 69, 0.2)",
          }}
        />
      ) : null}
    </Space>
  );
};
