import { useUrlQueryParam } from "utils/hooks/use-url-query-param";

export const useQrModal = () => {
  const [{ qrModalOpen }, setQrModalOpen] = useUrlQueryParam([
    "qrModalOpen",
    "qrKey",
  ]);

  const open = (id?: number) =>
    setQrModalOpen({ qrModalOpen: true, qrKey: id });
  const close = () =>
    setQrModalOpen({ qrModalOpen: undefined, qrKey: undefined });

  return [qrModalOpen === "true", open, close] as const;
};
