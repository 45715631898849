import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const DragDropType = "drag-row";

export default function View(props: any) {
    const { record, index, fnMove, fnEndMove, style, ...attrs } = props;
    const ref = useRef();

    const [dragAttrs, drag] = useDrag({
        type: DragDropType,
        item: record,
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        end: (item, monitor) => {
            fnEndMove(item);
        },
    });

    const [, drop] = useDrop({
        accept: DragDropType,
        collect: (monitor) => ({}),
        hover: (item: any, monitor) => {
            if (item.pid !== record.pid || item.id === record.id || item.pid === record.id) {
                return;
            }
            console.log(item.id, record.id);
            fnMove(item, record);
        },
        drop: (item: any, monitor) => {
            if (item.pid !== record.pid || item.id === record.id || item.pid === record.id) {
                return;
            }
            console.log(item.id, record.id);
            fnMove(item, record);
        },
    });

    drop(drag(ref));

    const opacity = dragAttrs.isDragging ? 0 : 1;
    const backgroundColor = record?.leaf ? (record?.pid ? "#F6F6F6" : "") : "";
    return <tr ref={ref} style={{ opacity, backgroundColor, ...style }} {...attrs} />;
}
