import React, { useEffect, useState } from "react";
import {
    ProfileOutlined,
    SettingOutlined,
    UploadOutlined,
    UnorderedListOutlined,
    RadiusSettingOutlined,
    LogoutOutlined,
    // UserOutlined,
    DownOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Dropdown, Layout, Menu, Space, theme } from "antd";
import type { MenuProps } from "antd";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

import { HeaderHeight, LayoutSpace } from "utils/const";
import { DataList } from "./operate-list";
import { DataUpload } from "./operate-upload";
// import { ModelSetting as ModelConfig } from "./config-model";
import ModelConfig from "./model-config";
import { useAuth } from "context/auth-context";
import {
    configKey,
    configModelKey,
    configModelRoute,
    keyPathMap,
    operateKey,
    operateListKey,
    operateListRoute,
    operateUploadKey,
    operateUploadRoute,
    rootSubmenuKeys,
} from "./setting";
import logoLarge from "assets/menu-large.png";
import logoSmall from "assets/menu-samll.png";

import "./index.css";

const { Header, Content, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const avatarHeight = 42;

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
};

const items: MenuItem[] = [
    getItem(keyPathMap[operateKey], operateKey, <ProfileOutlined />, [
        getItem(<Link to={operateListRoute}>{keyPathMap[operateListKey]}</Link>, operateListKey, <UnorderedListOutlined />),
        getItem(<Link to={operateUploadRoute}>{keyPathMap[operateUploadKey]}</Link>, operateUploadKey, <UploadOutlined />),
    ]),
    getItem(keyPathMap[configKey], configKey, <SettingOutlined />, [
        getItem(<Link to={configModelRoute}>{keyPathMap[configModelKey]}</Link>, configModelKey, <RadiusSettingOutlined />),
    ]),
];

const Home: React.FC = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [collapsed, setCollapsed] = useState(false);

    const location = useLocation();

    const { user, logout } = useAuth();

    const [menuRoute, setMenuRoute] = useState<{
        openKey: string;
        selectedKey: string;
    }>({ openKey: ``, selectedKey: "" });

    const logoutItems: MenuProps["items"] = [
        {
            key: "logout",
            label: "注销",
            icon: <LogoutOutlined />,
            onClick: logout,
        },
        // {
        //   key: "modPassword",
        //   label: "修改密码",
        //   icon: <UserOutlined />,
        //   // onClick: modPassword,
        // },
    ];

    useEffect(() => {
        if (location.pathname && location.pathname !== "/") {
            const locationSplit = location.pathname.split("/").filter((i) => i !== "");
            setMenuRoute({
                openKey: locationSplit[1],
                selectedKey: locationSplit[2],
            });
        }
    }, [location.pathname]);

    return (
        <Layout style={{ height: "100vh" }}>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                trigger={collapsed ? <MenuUnfoldOutlined className="sider-trigger" /> : <MenuFoldOutlined className="sider-trigger" />}>
                <div className="logo-content">{collapsed ? <img src={logoSmall} alt="infer-logo" /> : <img src={logoLarge} alt="infer-logo" />}</div>
                <Menu
                    theme="dark"
                    defaultOpenKeys={rootSubmenuKeys}
                    selectedKeys={[menuRoute.selectedKey]}
                    mode="inline"
                    items={items}
                    onClick={({ keyPath }) => {
                        setMenuRoute({
                            openKey: keyPath[1],
                            selectedKey: keyPath[0],
                        });
                    }}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        height: HeaderHeight,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <Breadcrumb
                        style={{
                            marginLeft: LayoutSpace,
                            height: HeaderHeight,
                            lineHeight: HeaderHeight,
                            flex: 1,
                        }}
                        items={[
                            {
                                title: keyPathMap[menuRoute.openKey],
                            },
                            {
                                title: keyPathMap[menuRoute.selectedKey],
                            },
                        ]}
                    />
                    <Dropdown menu={{ items: logoutItems }}>
                        <div
                            style={{
                                height: HeaderHeight,
                                margin: `0 ${LayoutSpace}`,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                            <Space>
                                <div
                                    style={{
                                        height: `${avatarHeight}px`,
                                        lineHeight: `${avatarHeight}px`,
                                        textAlign: "center",
                                    }}>
                                    {user?.name}
                                </div>
                                <DownOutlined style={{ fontSize: 12 }} />
                            </Space>
                        </div>
                    </Dropdown>
                </Header>
                <Content style={{ padding: LayoutSpace, overflow: "auto" }}>
                    <Routes>
                        <Route path={operateListRoute} element={<DataList />} />
                        <Route path={operateUploadRoute} element={<DataUpload />} />
                        <Route path={configModelRoute} element={<ModelConfig />} />
                        <Route path="*" element={<Navigate to={operateListRoute} replace />} />
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Home;
