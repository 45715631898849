import { ComponentProps, FC, useEffect, useState } from "react";
import { ColorPicker } from "antd";
import type { Color } from "antd/es/color-picker";

import { defaultColor } from "utils/const";

// const icon = `<svg t="1684409248855" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15026"><path d="M955.733333 280.849067v487.611733l-21.384533 12.288-400.913067 231.5776V524.5952l21.384534-12.288zM490.683733 524.5952v487.7312L89.770667 780.7488l-21.384534-12.288V280.849067l400.913067 231.458133zM934.3488 243.8656L533.435733 475.323733l-21.367466 12.288-21.384534-12.288L89.770667 243.8656 490.683733 12.288 512.068267 0l21.367466 12.288z" p-id="15027"></path></svg>`;

type BlockColorPickerType = ComponentProps<typeof ColorPicker>;

interface BlockColorPickerProps extends BlockColorPickerType {
    // size?: string | number;
    icon?: string;
    width?: string | number;
    height?: string | number;
}

export const BlockColorPicker: FC<BlockColorPickerProps> = ({ ...props }) => {
    const { value } = props;
    const [color, setColor] = useState<Color | string>(value || defaultColor);

    // const [isHover, setIsHover] = useState<boolean>(false);

    // const bgColor = useMemo<string>(() => {
    //   setColor(value || defaultColor);
    //   return typeof value === "string"
    //     ? value
    //     : value?.toHexString() || defaultColor;
    // }, [value]);

    useEffect(() => {
        setColor(value || defaultColor);
    }, [value]);

    const handleChange = (value: Color, hex: string) => {
        setColor(value || hex);
        if (props.onChange) {
            props.onChange(value, hex);
        }
    };

    return (
        <div
            style={{
                width: props.width,
                height: props.height || 32,
                display: "flex",
                alignItems: "center",
            }}>
            {/* <ColorPicker {...props} value={color} onChange={handleChange}>
        <div
          dangerouslySetInnerHTML={{ __html: props.icon || icon }}
          style={{
            fill: bgColor,
            width: props.size || "28px",
            height: props.size || "28px",
            // border: "1px solid #9999997f",
            // borderRadius: "4px"
            backgroundImage:
              isHover ? "conic-gradient(#EEE 0 25%, transparent 0 50%, #EEE 0 75%, transparent 0)" : undefined,
            backgroundSize: isHover ? "33% 33%" : undefined,
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        />
      </ColorPicker> */}
            <ColorPicker {...props} value={color} onChange={handleChange} />
            {/* <ColorPicker defaultValue={defaultColor} {...props}/> */}
        </div>
    );
};
