import { cloneDeep } from "lodash-es";

export const loopTree = (children: any[], stop = false, fn: any) => {
    for (let child of children) {
        const result = fn(child);
        if (result && stop) {
            return result;
        }
        if (child.children) {
            loopTree(child.children, stop, fn);
        }
    }
};

export const dragRow = (tableData: any[], dragItem: any, dropItem: any, fnMoved: any) => {
    const parent =
        loopTree(tableData, true, (item: any) => {
            if (item.id === dragItem.pid) {
                return item;
            }
        }) || {};
    const children: any[] = parent.children || tableData;
    let dragIdx = -1;
    let dropIdx = -1;
    for (let idx = 0; idx < children.length; idx++) {
        if (children[idx].id === dragItem.id) {
            dragIdx = idx;
        }
        if (children[idx].id === dropItem.id) {
            dropIdx = idx;
        }
        if (dragIdx >= 0 && dropIdx >= 0) {
            break;
        }
    }
    if (dragIdx === -1 || dropIdx === -1) {
        return;
    }
    const childrenNew = children.slice(0, dragIdx).concat(children.slice(dragIdx + 1));
    childrenNew.splice(dropIdx, 0, dragItem);
    if (parent.id) {
        const dataNew = cloneDeep(tableData);
        const parentNew =
            loopTree(dataNew, true, (item: any) => {
                if (item.id === dragItem.pid) {
                    return item;
                }
            }) || {};
        parentNew.children = childrenNew;
        fnMoved(dataNew);
    } else {
        fnMoved(childrenNew);
    }
};
