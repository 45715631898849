import {
  ChangeEvent,
  ComponentProps,
  FC,
  MutableRefObject,
  useState,
  KeyboardEvent,
  useRef,
} from "react";
import { Input, InputRef, message, Tooltip } from "antd";
import { useHttp } from "utils/http";
import { ActionType } from "@ant-design/pro-components";

type EditCallbackInputType = ComponentProps<typeof Input>;
interface EditCallbackInputProps extends EditCallbackInputType {
  need_callback?: string;
  callback_url?: string;
  callback_data?: string;
  callback_method?: string;
  action_ref?: MutableRefObject<ActionType | undefined>;
}

export const EditCallbackInput: FC<EditCallbackInputProps> = ({ ...props }) => {
  const {
    need_callback,
    callback_url,
    callback_data,
    callback_method,
    action_ref,
  } = props;
  const [value, setValue] = useState<string>();
  const [isEditting, setIsEditting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const client = useHttp();

  const inputRef = useRef<InputRef>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChanged(true);
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      inputRef.current?.blur();
    }
  };

  return (
    <Tooltip
      title={
        !isEditting &&
        (value !== undefined && value !== null ? value : props.defaultValue)
      }
    >
      <Input
        {...props}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        placeholder="输入备注"
        ref={inputRef}
        value={value}
        bordered={isEditting}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={() => {
          if (need_callback === "true" && callback_url && isChanged) {
            client(callback_url, {
              data: {
                [callback_data || "note"]: value,
              },
              method: callback_method,
            }).catch(() => {
              action_ref?.current?.reload();
              message.error("更新失败, 请重试");
            });
          }
          setIsEditting(false);
        }}
        onFocus={() => setIsEditting(true)}
      />
    </Tooltip>
  );
};
