import { message } from "antd";

export const http = async (input: RequestInfo, init?: RequestInit) => {
    return fetch(input, init)
        .then(async (response) => {
            if (response.ok) {
                try {
                    return await response.json();
                } catch (err) {
                    console.error(err);
                    return {};
                }
            } else {
                console.log(response);
                return {};
            }
        })
        .catch(async (err) => {
            message.error("请求失败，请稍后再试！");
            console.log(err);
            return {};
        });
};

export const update_part = async (body: object) => {
    return await http("/api/v1/conf/update_part", {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(body),
    });
};

export const list_level = async () => {
    const data = await http("/api/v1/conf/list_level");
    return data;
};

export const update_level = async (body: object) => {
    return await http("/api/v1/conf/update_level", {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(body),
    });
};

export const delete_level = async (id: any) => {
    return await http(`/api/v1/conf/delete_level?id=${id}`, {
        method: "DELETE",
    });
};

export const reorder_conf = async (body: object) => {
    return await http("/api/v1/conf/reorder_conf", {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(body),
    });
};

export const list_conf_tree = async () => {
    const data = (await http("/api/v1/conf/list_conf_tree"));
    const dataLevel = await list_level();
    return { rows: (data.data || {}).rows, bodyParts: dataLevel.data ||[] };
};

export const update_conf = async (body: object) => {
    return await http("/api/v1/conf/update_conf", {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(body),
    });
};

export const update_study = async (id: number, body: object) =>{
    return await http(`/api/v1/study/update_study?id=${id}`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(body),
    });
}

export const delete_conf = async (id: any) => {
    return await http(`/api/v1/conf/delete_conf?id=${id}`, {
        method: "DELETE",
    });
};

export const list_attachment = async (study_id: string, part_id?: string, part_name?: string) => {
    const data = await http(`/api/v1/attachment/list_atta?study_id=${study_id}&part_id=${part_id||""}&part_name=${part_name||""}`);
    const dataLevels = await list_level();
    return {attas:(data.data || {}).rows, parts: dataLevels.data}
};
