import { Space, Spin } from "antd";
import logo from "./logo/logo.png";
// import bg from "./logo/home_bg.png";

import "./index.css";

export const LoadingPage = () => {
  return (
    <div className="loading-page">
      <Space className="loading-page-space" direction="vertical">
        <img src={logo} alt="" width="256" />
        <Spin size="large" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "4rem",
          }}
        >
          InferVision
        </div>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
          }}
        >
          With A.I.,We Improve Human Life
        </span>
      </Space>
    </div>
  );
};
