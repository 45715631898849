import { useUrlQueryParam } from "utils/hooks/use-url-query-param";

export const useModifyModal = () => {
  const [{ modifyModalOpen }, setModifyModalOpen] = useUrlQueryParam([
    "modifyModalOpen",
    "modifyKey",
  ]);

  const open = (id?: number) =>
    setModifyModalOpen({ modifyModalOpen: true, modifyKey: id });
  const close = () =>
    setModifyModalOpen({ modifyModalOpen: undefined, modifyKey: undefined });

  return [modifyModalOpen === "true", open, close] as const;
};
