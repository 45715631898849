const prefix = "dm";

/**
 * 运营管理
 *    -- 上传列表
 *    -- 数据上传
 * 配置管理
 *    -- 模型配置
 *
 */
export const operateKey = "operate"; // 一级 运营管理
export const operateListKey = "list"; // 二级 上传列表
export const operateUploadKey = "upload"; // 二级 数据上传
export const configKey = "config"; // 一级 配置管理
export const configModelKey = "model"; // 二级 模型配置

// 一级集合
export const rootSubmenuKeys = [operateKey, configKey];

export const keyPathMap: { [key: string]: string } = {
  [operateKey]: "运营管理",
  [operateListKey]: "上传列表",
  [operateUploadKey]: "数据上传",
  [configKey]: "配置管理",
  [configModelKey]: "模型配置",
};

// 路由
export const operateListRoute = `/${prefix}/${operateKey}/${operateListKey}`;
export const operateUploadRoute = `/${prefix}/${operateKey}/${operateUploadKey}`;
export const configModelRoute = `/${prefix}/${configKey}/${configModelKey}`;
