import { Input, Tooltip } from "antd";
import { ComponentProps, FC, useState } from "react";

type EditInputType = ComponentProps<typeof Input>;
interface EditInputProps extends EditInputType {}

export const EditInput: FC<EditInputProps> = ({ ...props }) => {
  const [isEditting, setIsEditting] = useState(false);

  return (
    <Tooltip
      title={
        !isEditting &&
        (props.value !== undefined && props.value !== null
          ? props.value
          : props.defaultValue)
      }
    >
      <Input
        {...props}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        bordered={isEditting}
        onBlur={() => setIsEditting(false)}
        onFocus={() => setIsEditting(true)}
      />
    </Tooltip>
  );
};
