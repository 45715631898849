import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { App, ConfigProvider } from "antd";
import locale from "antd/es/locale/zh_CN";

import { AppProvider } from "context";
import DmApp from "App";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <ConfigProvider locale={locale}>
            <AppProvider>
                <App>
                    <BrowserRouter>
                        <DmApp />
                    </BrowserRouter>
                </App>
            </AppProvider>
        </ConfigProvider>
    </React.StrictMode>,
);
