import { Alert, Button, QRCode, Spin } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { exportComponentAsJPEG } from "react-component-export-image";
import dayjs from "dayjs";

import { RowModal } from "components/RowModal";
import { useQrModal } from "auth-app/pc/hooks/operate-list/table/useQrModal";
import { useEffect, useRef, useState } from "react";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/use-async";
import { GET_QR } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/use-url-query-param";
import { QrType } from "lib/interface";
import { prefixH5 } from "utils/const";
import { useImmer } from "use-immer";

const qrSize = 160;
const now = new Date();

export const QrModal = () => {
    const [isOpen, , close] = useQrModal();
    const [{ qrKey }] = useUrlQueryParam(["qrKey"]);
    const componentRef = useRef(null);

    const [qrData, setQrData] = useImmer({
        loading: false,
        error: false,
        qr: {
            key: "",
            code: "",
            expire_date: "",
        },
    });

    const handleRequest = () => {
        setQrData(draft=>{
          draft.loading = true;
        })
        fetch(`${GET_QR}?id=${qrKey}`).then(async (res) => {
            const resp = await res.json();
            setQrData((draft) => {
                draft.qr = resp.data || {};
                console.log(draft.qr);
                draft.loading = false;
            });
        });
    };

    useEffect(() => {
        if (isOpen) {
            handleRequest();
        }
    }, [isOpen]);

    return (
        <RowModal
            maskClosable={true}
            width={"30rem"}
            style={{ minWidth: "30rem" }}
            bodyStyle={{
                padding: 0,
                margin: 0,
            }}
            okText="保存"
            title="导出二维码"
            open={isOpen}
            onCancel={close}
            onOk={() =>
                exportComponentAsJPEG(componentRef, {
                    fileName: `${dayjs(now).format("YYYYMMDD")}.jpg`,
                })
            }>
            <div
                ref={componentRef}
                style={{
                    height: "24rem",
                    width: "30rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0 4rem",
                }}>
                <div
                    style={{
                        width: qrSize,
                        height: qrSize,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid rgba(5, 5, 5, 0.06)",
                        borderRadius: "8px",
                    }}>
                    {qrData.error ? (
                        <Spin spinning={qrData.loading}>
                            <div
                                style={{
                                    width: qrSize,
                                    height: qrSize,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "rgba(245, 245, 245, 0.36)",
                                    borderRadius: "8px",
                                }}>
                                {qrData.loading ? null : (
                                    <Button style={{ padding: 0, margin: 0 }} type="link" icon={<ReloadOutlined />} onClick={handleRequest}>
                                        点击重试
                                    </Button>
                                )}
                            </div>
                        </Spin>
                    ) : (
                        <QRCode
                            value={`${window.location.origin}/h5/${prefixH5}?key=${qrData.qr.key}${qrData.qr.code ? `&code=${qrData.qr.code}` : ""}`}
                            status={qrData.loading ? "loading" : "active"}
                            onRefresh={handleRequest}
                            size={qrSize}
                        />
                    )}
                </div>
                {qrData.error && !qrData.loading && <Alert style={{ marginTop: 8 }} message="生成二维码失败，请重试" type="error" />}
                <span style={{ marginTop: 16 }}>请用手机扫码识别，查看三维可视化模型</span>
                {qrData.qr.expire_date && <Alert style={{ marginTop: 8 }} message={`注: 此二维码于（${qrData.qr.expire_date}）失效`} type="warning" />}
            </div>
        </RowModal>
    );
};
