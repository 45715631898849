import { MutableRefObject, useEffect, useState } from "react";
import dayjs from "dayjs";

import { RowModal } from "components/RowModal";
import { useModifyModal } from "auth-app/pc/hooks/operate-list/table/useModifyModal";
import { ModifyInfo, infoSubType } from "./modify-info";
import { DataSourceType, ModifyStl } from "./modify-stl";
import { useAsync } from "utils/hooks/use-async";
import { ConfigurationType, FileType, ListDataType } from "lib/interface";
import { useHttp } from "utils/http";
import { GET_STUDY, GET_UPDATE_STUDY, UPDATE_STUDY } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/use-url-query-param";
import { Form, Spin, message } from "antd";
import { DateFormat, initObj } from "utils";
import { ActionType } from "@ant-design/pro-components";

export const ModifyModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const [isOpen, , close] = useModifyModal();
  const client = useHttp();

  const { run, data, isIdle, isLoading } = useAsync<ListDataType>();
  const { run: getUpdateInfo, isLoading: upLoading } = useAsync<ListDataType>();

  const [files, setFiles] = useState<FileType[]>([]);
  const [configurations, setConfigurations] = useState<ConfigurationType[]>([]);

  const [bodyPart, setBodyPart] = useState<string | null>(null);

  const [{ modifyKey }] = useUrlQueryParam(["modifyKey"]);

  const [infoForm] = Form.useForm<infoSubType>();
  const [dataSource, setDataSource] = useState<readonly DataSourceType[]>([]);

  const onSubmit = (value: infoSubType, files: readonly DataSourceType[]) => {
    client(UPDATE_STUDY(modifyKey), {
      data: {
        ...initObj(value),
        study_date: dayjs(value.study_date).format(DateFormat),
        files: files.map((f) => ({ ...initObj(f) })),
      },
      method: "PUT",
    }).then(() => {
      message.success("更新成功");
      actionRef.current?.reload();
      close();
    });
  };

  const handleRequest = () => {
    run(client(GET_STUDY, { data: { id: modifyKey } })).then((res) => {
      setBodyPart(res?.body_part || null);
      setFiles(res?.files || []);
      setConfigurations(res?.configurations || []);
    });
  };

  const handleUpdateBodyPart = (bodyPart: string) => {
    if (bodyPart) {
      getUpdateInfo(client(GET_UPDATE_STUDY(modifyKey, bodyPart))).then(
        (res) => {
          setFiles(res?.files || []);
          setConfigurations(res?.configurations || []);
        }
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const Loading = isIdle || isLoading;

  return (
    <RowModal
      bodyStyle={{
        paddingTop: 32,
        justifyContent: "start",
        overflow: "auto",
        height: "80vh",
      }}
      title="调整数据"
      open={isOpen}
      onCancel={() => {
        infoForm.resetFields();
        close();
      }}
      onOk={() => {
        onSubmit(infoForm.getFieldsValue(), dataSource);
      }}
    >
      <Spin spinning={Loading}>
        <ModifyInfo
          loading={upLoading}
          data={data}
          form={infoForm}
          setBodyPart={setBodyPart}
          handleUpdateBodyPart={handleUpdateBodyPart}
        />
        <ModifyStl
          // data={data}
          files={files}
          configurations={configurations}
          body_part={bodyPart}
          dataSource={dataSource}
          setDataSource={setDataSource}
        />
      </Spin>
    </RowModal>
  );
};
