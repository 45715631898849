import React, { ReactNode, useState } from "react";

import * as auth from "auth-provider";
import { LoginForm, User } from "lib/interface";
import { useMount } from "utils/hooks/use-mount";

const bootstrapUser = async () => {
  let user: User | null = null;
  const token = auth.getToken();
  if (token) {
    try {
      user = await auth.loginAuto(token);
    } catch {
      auth.logout();
    }
  }
  return user;
};

const AuthContext = React.createContext<
  | {
      user: User | null;
      login: ({ username, password }: LoginForm) => Promise<void>;
      loginAuto: (token: string) => Promise<void>;
      loginDingtalk: (token: string) => Promise<void>;
      logout: () => Promise<void>;
      isLogining: boolean;
      isIdle: boolean;
    }
  | undefined
>(undefined);

AuthContext.displayName = "AuthContext";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  const [isIdle, setIsIdle] = useState(true);
  const [isLogining, setIsLogining] = useState(false);

  const login = ({ username, password }: LoginForm) =>
    auth
      .login({ username, password })
      .then(setUser)
      .catch((e) => {
        throw e;
      });
  const loginAuto = (token: string) =>
    auth
      .loginAuto(token)
      .then(setUser)
      .catch((e) => {
        throw e;
      });
  const loginDingtalk = (code: string) =>
    auth
      .loginDingtalk(code)
      .then(setUser)
      .catch((e) => {
        throw e;
      });
  const logout = () => auth.logout().then(() => setUser(null));

  useMount(() => {
    setIsIdle(false);
    setIsLogining(true);
    bootstrapUser()
      .then(setUser)
      .finally(() => setIsLogining(false));
  });

  return (
    <AuthContext.Provider
      children={children}
      value={{
        user,
        login,
        loginAuto,
        loginDingtalk,
        logout,
        isLogining,
        isIdle,
      }}
    />
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth必须在AuthProvide中使用");
  }
  return context;
};
