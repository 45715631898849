import dayjs from "dayjs";

import "./index.css";

const now = Date();

export const Copyright = () => {
  return (
    <div className="sider-copyright">
      Copyright© {dayjs(now).format("YYYY")} 推想科技
    </div>
  );
};
