import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Upload, message } from "antd";
import type { UploadFile, UploadProps } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import {
  UploadHeaders,
  beforeUpload,
  handleUpload,
} from "auth-app/pc/pages/operate-upload/upload/upload";

import { DEL_STUDY, UPLOAD } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/use-url-query-param";
import { useHttp } from "utils/http";
import { useUploadModal } from "auth-app/pc/hooks/operate-list/table/useUploadModal";

const { Dragger } = Upload;

export const FileUpload = ({
  style,
  limitOne = false,
  isUpdate = false,
  setFolderId,
}: {
  style?: CSSProperties;
  limitOne?: boolean;
  isUpdate?: boolean;
  setFolderId?: Dispatch<SetStateAction<string | number | undefined>>;
}) => {
  const [isOpen] = useUploadModal();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [{ uploadKey }] = useUrlQueryParam(["uploadKey"]);

  const clinet = useHttp();

  const props: UploadProps = {
    headers: UploadHeaders,
    maxCount: limitOne ? 1 : undefined,
    fileList: fileList,
    multiple: limitOne ? false : true,
    action: uploadKey ? `${UPLOAD}/?study_id=${uploadKey}` : UPLOAD,
    onRemove: isUpdate
      ? () => setFolderId?.(undefined)
      : (file) => {
          const { study_id } = file.response || {};
          if (study_id !== undefined && study_id !== null) {
            clinet(DEL_STUDY(study_id), { method: "DELETE" }).then(() =>
              message.success("删除成功")
            );
          }
        },
    beforeUpload: beforeUpload,
    onChange: (info) => handleUpload(info, setFileList, setFolderId),
  };

  useEffect(() => {
    if (isOpen) {
      setFileList([]);
    }
  }, [isOpen]);

  return (
    <div style={{ width: "45rem", minHeight: "18rem", ...style }}>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined style={{ fontSize: 72 }} />
        </p>
        <p className="ant-upload-text">请点击或将文件拖拽到这里上传</p>
        <p className="ant-upload-hint">支持扩展名: .rar .zip</p>
      </Dragger>
    </div>
  );
};
