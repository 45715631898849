import React from "react";

export const trim = (Comp) =>
    class extends React.Component {
        onBlur = (evt) => {
            evt.target.value = evt.target.value.trim();
            if (this.props.onChange) {
                this.props.onChange(evt);
            }
        };

        render() {
            return <Comp {...this.props} onBlur={this.onBlur}></Comp>;
        }
    };

export const edit = (Comp, rewriteChange) =>
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = { editing: false };
        }

        onBlur = (evt) => {
            this.setState({ editing: false });
            if (rewriteChange && this.props.onChange) {
                this.props.onChange(evt.target.value);
            }
        };

        onFocus = (evt) => {
            this.setState({ editing: true });
        };

        render() {
            const editingAttrs = {
                bordered: this.state.editing,
                onBlur: this.onBlur,
                onFocus: this.onFocus,
            };
            if (rewriteChange) {
                editingAttrs.onChange = () => {};
            }
            return <Comp {...this.props} {...editingAttrs}></Comp>;
        }
    };
