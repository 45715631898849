import { useState, KeyboardEvent } from "react";
import { Alert, Button, Space } from "antd";
import { WarningFilled } from "@ant-design/icons";

import { useAuth } from "context/auth-context";
import userSvg from "assets/login/username.svg";
import pwdSvg from "assets/login/password.svg";
import eyeClose from "assets/login/eyeClose.svg";
import eyeOpen from "assets/login/eyeOpen.svg";

import "./index.css";

export const LoginPasswordForm = () => {
  const [isText, setIstext] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [isLogining, setIsLogining] = useState(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { login } = useAuth();

  const handleLogin = () => {
    try {
      setIsLogining(true);
      login({ username, password })
        .catch((e) => {
          console.error(e);
          if (e?.status === 401) {
            setIsLoginError(true);
          }
        })
        .finally(() => setIsLogining(false));
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="tx-login-container">
      <Space direction="vertical" size={28}>
        <div className="input-container">
          <span className="input-icon-container">
            <img src={userSvg} alt="logo" />
          </span>
          <input
            disabled={isLogining}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            className="input"
            type="text"
            placeholder="用户名"
          />
        </div>
        <div className="input-container">
          <span className="input-icon-container">
            <img src={pwdSvg} alt="logo" />
          </span>
          <input
            disabled={isLogining}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            className="input"
            type={isText ? "text" : "password"}
            placeholder="密码"
            onKeyDown={handleKeyDown}
          />
          <button
            type="button"
            onClick={() => setIstext(!isText)}
            className="input-icon-container-span"
          >
            {isText ? (
              <img className="inputIcon" src={eyeOpen} alt="logo" />
            ) : (
              <img className="inputIcon" src={eyeClose} alt="logo" />
            )}
          </button>
        </div>
        {isLoginError ? (
          <Alert
            message={
              <Space style={{ color: "#D43645", fontSize: "16px" }}>
                <WarningFilled />
                <span>用户名/密码错误</span>
              </Space>
            }
            type="error"
            style={{
              textAlign: "center",
              border: "1px solid #D43645",
              backgroundColor: "rgba(212, 54, 69, 0.2)",
            }}
          />
        ) : null}
      </Space>
      <Button
        loading={isLogining}
        type="primary"
        size="large"
        block
        style={{ marginTop: "24px" }}
        onClick={handleLogin}
      >
        登录
      </Button>
    </div>
  );
};
