export const LoginWelcomChar = () => {
  return (
    <div>
      <p
        style={{
          fontSize: "36px",
          height: "50px",
          lineHeight: "50px",
          margin: "0 0 10px 0",
          fontFamily: " PingFangSC-Semibold, PingFang SC",
          width: "72px",
          fontWeight: 600,
          color: " #FFFFFF",
        }}
      >
        登录
      </p>
      <span
        style={{
          fontSize: "14px",
          height: "20px",
          lineHeight: "20px",
          color: "#9A9A9A",
          fontWeight: 400,
          fontFamily: "PingFangSC-Regular, PingFang SC",
        }}
      >
        欢迎使用移动三维数据管理平台
      </span>
    </div>
  );
};
