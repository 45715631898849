import { Modal, Form, Input, Select, message } from "antd";
import { useEffect } from "react";
import { useImmer } from "use-immer";

import * as api from "../api";
import { trim } from "../util-view";

import "./add-level.css";

const TrimInput = trim(Input);

type FieldType = {
    name?: string;
    part_id?: number;
};

export default function View({ target, onOk, onCancel, isOpen, bodyParts }: any) {
    const [data, updateData] = useImmer({
        title: "",
        confirmLoading: false,
        bodyOptions: [] as any,
    });
    const [formRef] = Form.useForm();

    const onSubmit = (values: any) => {
        updateData((draft) => {
            draft.confirmLoading = true;
        });
        values.id = target.id;
        api.update_level(values).then((resp) => {
            const fnMsg = resp.code === 1 ? message.info : message.warning;
            const msg = resp.msg || (resp.code === 1 ? "请求成功！" : "请求失败！");
            fnMsg(msg);
            updateData((draft) => {
                draft.confirmLoading = false;
            });
            if (resp.code === 1) {
                formRef.resetFields();
                onOk();
            }
        });
    };

    useEffect(() => {
        updateData((draft) => {
            draft.title = target.id ? "修改层级" : "新增层级";
            draft.confirmLoading = false;
            draft.bodyOptions = bodyParts.map((it: { id: any; name: any }) => ({ value: it.id, label: it.name }));
        });
        formRef.setFieldsValue({ part_id: target.part_id, name: target.name });
    }, [updateData, bodyParts, target, formRef]);

    return (
        <Modal centered={true} title={data.title} open={isOpen} confirmLoading={data.confirmLoading} onOk={() => formRef.submit()} onCancel={onCancel}>
            <Form labelCol={{span: 6}} form={formRef} onFinish={onSubmit} className="h-center">
                <Form.Item<FieldType> className="form-item" label="部位" name="part_id" rules={[{ required: true, message: "请选择部位!" }]}>
                    <Select options={data.bodyOptions} allowClear={true} />
                </Form.Item>
                <Form.Item<FieldType> className="form-item" label="层级" name="name">
                    <TrimInput />
                </Form.Item>
            </Form>
        </Modal>
    );
}
