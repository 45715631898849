import { useEffect, useCallback } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Table, ColorPicker, Button, Space } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useImmer } from "use-immer";
import { ColorFactory } from "antd/es/color-picker/color";
import DragRow from "./drag-row";
import { dragRow } from "../util";

import "./config-list.css";

const createColumns = (fnGoEdit: any, fnGoDelete: any) => {
    const columns = [
        {
            title: "层级",
            dataIndex: "level_name",
            render: (text: string, record: any) => {
                return record.leaf ? (record.pid ? "" : "无") : `(${record.children.length})  ${record.name}`;
            },
        },
        {
            title: "文件名称",
            dataIndex: "file_name",
        },
        {
            title: "展示名称",
            dataIndex: "display_name",

        },
        {
            title: "颜色",
            dataIndex: "model_color",
            render: (text: string, record: any) => {
                if (record.leaf) {
                    let opacity = Number(record.default_opacity === null ? 50 : record.default_opacity);
                    opacity = Object.is(opacity, NaN) ? 50 : opacity;
                    const rgb = new ColorFactory(record.model_color || "#999999").toRgb();
                    const color = new ColorFactory({ ...rgb, a: opacity / 100 }).toHexString();
                    return <ColorPicker value={color} open={false} className="disable" />;
                }
                return "";
            },
        },
        {
            title: "透明度",
            dataIndex: "default_opacity",
            render: (text: string, record: any) => {
                let opacity = Number(record.default_opacity === null ? 50 : record.default_opacity);
                opacity = Object.is(opacity, NaN) ? 50 : opacity;
                return record.leaf ? opacity : "";
            },
        },
        {
            title: "部位",
            dataIndex: "part_name"
        },
        {
            title: "操作",
            render: (text: string, record: any) => {
                return (
                    <Space>
                        <Button
                            className="no-space"
                            type="link"
                            onClick={() => {
                                fnGoEdit(record);
                            }}>
                            编辑
                        </Button>
                        <Button
                            className="no-space"
                            type="link"
                            onClick={() => {
                                fnGoDelete(record);
                            }}>
                            删除
                        </Button>
                    </Space>
                );
            },
        },
    ];
    return columns;
};

export default function View({ bodyPart, allData, fnGoEdit, fnGoDelete, fnOnOrder }: any) {
    const columns = createColumns(fnGoEdit, fnGoDelete);

    const [data, updateData] = useImmer({
        bodyPart: {} as any,
        tableData: [] as any[],
        draged: false,
        allData,
    });

    const fnMove = (dragItem: any, dropItem: any) => {
        dragRow(data.allData, dragItem, dropItem, (newData: any[]) => {
            reload(newData, true);
        });
    };

    const fnEndMove = () => {
        if (data.draged) {
            fnOnOrder(data.allData);
        }
        updateData((draft) => {
            draft.draged = false;
        });
    };

    const reload = useCallback(
        (newData: any[], draged: boolean) => {
            updateData((draft) => {
                draft.tableData = bodyPart.id === "*" ? newData : newData.filter((it: any) => it.part_name === bodyPart.name);
                draft.allData = newData;
                draft.draged = draged;
            });
        },
        [updateData, bodyPart],
    );

    useEffect(() => {
        reload(allData, false);
    }, [reload, allData]);

    const expandIcon = ({ expanded, onExpand, record }: any) => {
        if (!record.leaf) {
            const Icon = expanded ? MinusOutlined : PlusOutlined;
            return (
                <Icon
                    className={record.children.length > 0 ? "expand-on" : " expand-off"}
                    onClick={(evt) => {
                        onExpand(record, evt);
                    }}
                />
            );
        }
        return "";
    };

    return (
        <div className="list-box">
            <DndProvider backend={HTML5Backend}>
                <Table
                    columns={columns}
                    dataSource={data.tableData}
                    components={{ body: { row: DragRow } }}
                    rowKey={(record) => `${record.pid}-${record.id}`}
                    pagination={false}
                    childrenColumnName="children"
                    expandable={{
                        expandIcon: expandIcon,
                    }}
                    onRow={(record, index) => {
                        return {
                            record,
                            index,
                            fnMove,
                            fnEndMove,
                        } as any;
                    }}
                />
            </DndProvider>
        </div>
    );
}
