import "./index.css";

export const LoginRightContent = () => {
  return (
    <div className="right-content">
      <p className="right-content-text1">移动三维数据管理平台</p>
      <p className="right-content-text2">With A.I., We Improve Human Life</p>
    </div>
  );
};
