import { useMemo, useState } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { cleanObject } from "utils";

const useSetUrlSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (params: Partial<{ [key in string]: unknown }>) => {
    const o = cleanObject({
      ...Object.fromEntries(searchParams),
      ...params,
    }) as URLSearchParamsInit;
    return setSearchParams(o);
  };
};

export const useUrlQueryParam = <K extends string>(keys: K[]) => {
  const [searchParams] = useSearchParams();
  const setUrlSearchParam = useSetUrlSearchParam();
  const [stateKeys] = useState(keys);
  return [
    useMemo(() => {
      return stateKeys.reduce(
        (pre, key) => ({ ...pre, [key]: searchParams.get(key) || "" }),
        {} as { [key in K]: string }
      );
    }, [searchParams, stateKeys]),
    (params: Partial<{ [key in K]: unknown }>) => {
      return setUrlSearchParam(params);
    },
  ] as const;
};
